import { observer } from "mobx-react";
import { useEffect } from "react";
import logo from '../../assets/images/engelendael.svg'
import styles from '../../assets/scss/spinner.module.scss'
import { SpinnerRound } from 'spinners-react';
import { getCookieValue } from "../../utils";
import { useRedirect } from "../../hooks";


const Loader = observer(() => {
  const { redirect } = useRedirect();

  useEffect(() => {
    const tokenValues= getCookieValue()
    if (tokenValues !== undefined && tokenValues.expiring > Date.now()) {

      redirect("/control")
      return
    } 

    redirect("/login")
  }, [])

  return (
    <>
      <header className={styles.header}>
        <img src={logo} alt="engelendael logo"/>
      </header>

      <main>
        <div className={styles.spinnerContainer}>
          <SpinnerRound size="100" color="#E51F1F"/>
        </div>
      </main>
    </>

  )
})

export default Loader;
