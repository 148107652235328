import { observer } from "mobx-react";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import { globalStore } from "../stores";
import { Login, Control, Loader } from './pages'
import { Footer } from './partials';


type PrivateRouteProps = {
  element: JSX.Element
}

const PrivateRoute = observer(({ element }: PrivateRouteProps) => {
  const { hasAccess, loading } = globalStore;

  if (!hasAccess && !loading) {
    return <Navigate to="/login" />
  }

  return element
});

const AccessWatcher = observer(() => {
  const { hasAccess, loading } = globalStore;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!hasAccess && location.pathname !== "/login" && !loading) {
      navigate("/login")
    }

    if (hasAccess && location.pathname !== "/control" && !loading) {
      navigate("/control")
    }
  }, [hasAccess])
  return null
});


const Router = () => {
  return (
    <BrowserRouter>
      <AccessWatcher />
      <Routes>
        <Route path="/" element={<Login />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/control" element={<PrivateRoute element={<Control />}/>}/>
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default Router;

