import { makeObservable, observable } from "mobx";
import { store } from ".";
import { Auth } from '../components/models/';
import { doFetch } from '../utils';

const URLS = {
  VALIDATE_PASSPHRASE: '/api/auth/validate/pp'
}

class AuthStore extends store {
  auth: Auth

  constructor() {
    super()
    this.auth = new Auth()

    makeObservable(this, {
      auth: observable
    });
  }

  validatePassphrase = async () => {
    const { status } = await doFetch(URLS.VALIDATE_PASSPHRASE, 'POST', {
      passphrase: this.auth.passphrase
    });

    // reset the form
    if (status === 200) {
      this.auth = new Auth();
    }

    return { status }
  }
}

export default new AuthStore();
