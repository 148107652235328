import { observer } from "mobx-react";
import styles from '../../assets/scss/footer.module.scss';

const Footer = observer(() => {
  return (
    <footer className={styles.footer}>
      © Hoeve Engelendael {new Date().getFullYear()}
    </footer>
  )
})

export default Footer;

