import { observer } from 'mobx-react'
import { LogOut } from 'lucide-react';

import { Button } from '../elements/'
import { controlStore, globalStore, toastStore } from '../../stores';

import logo from '../../assets/images/engelendael.svg'
import styles from '../../assets/scss/control.module.scss'
import { useState } from 'react'

const Login = observer(() => {
  const [error, setError] = useState<undefined|string>(undefined)

  const logout = () => {
    document.cookie = "gate-control=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    globalStore.hasAccess = false;
  };

  const ControlPort = async () => {
    try {
      const { status } = await controlStore.control()
      if (status === 429) {
        setError("Probeer het later opnieuw.")
        return
      }

      setError(undefined);
      toastStore.success("Even geduld, de poort doet het nodige.");
    } catch {
      setError('Er ging iets mis. Probeer later opnieuw...')
    }
  };

  return (
    <>
      <header className={styles.header}>
        <img src={logo} alt="engelendael logo"/>

        <div className={styles.ctaBtn} onClick={logout}>
          <LogOut />
          <span>Logout</span>
        </div>
      </header>

      <main>
        {
          error !== undefined && (
            <div className="error">
            { error }
            </div>
          )
        }
        <div className={styles.loginContainer}>
        <span className={styles.info}>
          Via onderstaande knop kan je de poort open of toe doen. Let er steeds op dat wanneer de poort gesloten is, je deze na gebruik opnieuw sluit.
        </span>
          <Button text="Open / Toe" onClick={ControlPort}/>
        </div>

      </main>
    </>
  )
})

export default Login;

