import { observer } from "mobx-react";

interface props {
  text: string,
  icon?: string,
  onClick: () => void
}

const Button = observer(({ text, icon, onClick }: props) => {
  return (
    <div className="btn" onClick={onClick}>
      {
        icon !== undefined && (
          <i className={icon} />
        )
      }

      { text }
    </div>
  )
})

export default Button;
