import { observer } from "mobx-react";
import { Toast } from "../models";

import styles from '../../assets/scss/toast.module.scss';
import { X } from 'lucide-react';
import { motion } from "framer-motion"
import { toastStore } from "../../stores";

type ToastProps = {
  toast: Toast,
  idx: number,
}

const ToastElement = observer(({ toast, idx }: ToastProps) => {
  const close = () => {
    toastStore.removeToast(toast.id)
  };

  return (
      <motion.div 
        className={`${styles.toastContainer} ${toast.type === 'success' ? styles.success : styles.error}`}
        layout
        initial={{ opacity: 0, y: 50, scale: 0.3 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0, y: 20, scale: 0.5 }}
        style={{ top: `${(idx * 70) + 20}px`}}
      >
          <span>{ toast.message }</span>
          <X onClick={close}/>
      </motion.div>
  )
});

export default ToastElement;

