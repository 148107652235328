import { observer } from 'mobx-react'

import { Button, Input, Label } from '../elements/'
import { useRedirect } from '../../hooks'
import { authStore, globalStore } from '../../stores';

import logo from '../../assets/images/engelendael.svg'
import styles from '../../assets/scss/login.module.scss'
import { useState } from 'react'

const Login = observer(() => {
  const { redirect } = useRedirect();
  const [error, setError] = useState<undefined|string>(undefined)
  const { auth } = authStore;

  const validatePassphrase = async () => {
    const { status } = await authStore.validatePassphrase();
    switch(status) {
      case 200:
        setError(undefined);
        globalStore.hasAccess = true;
        redirect("/control");
      break;
      case 401:
        setError('De code is ongeldig, of wordt in een niet toegestane periode gebruikt.');
      break;
      default:
        setError('Er ging iets mis. Probeer later opnieuw...');
    }
  };

  const onEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      validatePassphrase();
    }
  };

  return (
    <>
      <header className={styles.header}>
        <img src={logo} alt="engelendael logo"/>
      </header>

      <main>
        {
          error !== undefined && (
            <div className="error">
            { error }
            </div>
          )
        }
        <div className={styles.loginContainer} onKeyDown={onEnter}>
          <Label htmlFor="Vul de sleutel in" />
          <Input 
            entity={auth} 
            name="passphrase" 
            placeholder="Mijn geheime sleutel"
            type="password"
          />
          <Button text="Valideer" onClick={validatePassphrase}/>
        </div>
      </main>
    </>
  )
})

export default Login;
