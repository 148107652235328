import jwt_decode from "jwt-decode";

export const doFetch = async (url: string, method: string, data?: any): Promise<{status: number, data: any}>  => {
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    mode: 'no-cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
    },
    body: method !== 'GET' ? JSON.stringify(data) : undefined // body data type must match "Content-Type" header
  });

  const parseData = response.json();

  return {
    status: response.status,
    data: parseData
  }
}

export const getCookieValue = () => {
  try {
    const token = document.cookie.replace("gate-control", "")

    console.log(token);

    if (token !== undefined && token !== null) {
      const decoded = jwt_decode(token) as {
        expiring: number,
        id: string,
        name: string,
      };

      return decoded
    }

  } catch (err) {
    console.log(err, 'err in get cookie value');
  }
}

