import { store } from ".";
import { doFetch } from '../utils';

const URLS = {
  CONTROL: '/api/control/toggle'
}

class ControlStore extends store {
  control = () => {
    return doFetch(URLS.CONTROL, 'POST');
  }
}

export default new ControlStore();
