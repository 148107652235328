import { makeObservable, observable } from "mobx";
import { getCookieValue } from "../utils";

class GlobalStore {
  hasAccess: boolean;
  loading: boolean;

  constructor() {
    this.hasAccess = false;
    this.loading = true;
    this.validateCookie();
    setInterval(this.validateCookie, 2000);

    makeObservable(this, {
      loading: observable,
      hasAccess: observable,
    })
  }

  validateCookie = () => {
    try {
      const cookie = getCookieValue();

      if (cookie === undefined) {
        this.hasAccess = false;
        return;
      }

      if (cookie.expiring < Date.now()) {
        this.hasAccess = true;
        return;
      }

      this.hasAccess = false;
    } catch (err) {
      this.hasAccess = false;
    }

    this.loading = false
  };
}

export default new GlobalStore();
