class Model {
  validation: any

  constructor() {
    this.validation = {}
  }
}

export default Model

