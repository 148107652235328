import { observable, makeObservable } from 'mobx';
import Model from './Model'

class Auth extends Model {
  passphrase: string

  constructor() {
    super();
    this.passphrase = '';

    makeObservable(this, {
      passphrase: observable
    })
  }
}

export default Auth
