import { observer } from "mobx-react";

interface props {
  htmlFor: string
  values?: any
}

const Label = observer(({ htmlFor, values }: props) => {
  return (
    <label>
      { htmlFor }
    </label>
  )
});

export default Label;
